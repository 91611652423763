/*
  Example Data: Products
  Use your own real API instead
*/

export const products = [
  {
    id: 0,
    categoryId: 0,
    categorySlug: "lcd",
    title: "«Sex, Drugs, Apteka.ru»",
    type: "T-Shirt",
    cover: "lcd-01.jpg",
    price: "19.99",
    date: "2021-04-27",
    params: {
      size: ["S", "M", "L"],
      color: ["Black", "White"],
      material: ["Cotton"],
    },
    desc: "<p>Own a part of esports history. Backpacks made from upcycled ESL event brandings - Each backpack is a unique piece.</p><p><b>Limited & unique</b>: By using already printed and finite materials every backpack looks slightly different and every edition is limited.</p><p><b>Sustainable</b>: We collected and re-used event brandings which normally end up in the trash.</p><p><b>Handcrafted</b>: Each event branding was spotted, washed, cutted, designed and sewn by hand.</p><p><b>Fair</b>: Produced in Portugal under fair conditions.</p><ul><li>High-quality and comfortable material</li><li>Print on the chest</li><li>Apteka.ru patch on lower back</li></ul><p><b>Please note:</b> Picture is an example only!</p>",
  },
  {
    id: 1,
    categoryId: 0,
    categorySlug: "lcd",
    title: "«The Spirit Molecule»",
    type: "T-Shirt",
    cover: "lcd-02.jpg",
    price: "9.99",
    date: "2021-04-27",
    params: {
      size: ["XS", "S", "M", "L"],
      color: ["Black", "White"],
      material: ["Cotton", "Polyester"],
    },
    desc: "<p>Own a part of esports history. Backpacks made from upcycled ESL event brandings - Each backpack is a unique piece.</p><p><b>Limited & unique</b>: By using already printed and finite materials every backpack looks slightly different and every edition is limited.</p><p><b>Sustainable</b>: We collected and re-used event brandings which normally end up in the trash.</p><p><b>Handcrafted</b>: Each event branding was spotted, washed, cutted, designed and sewn by hand.</p><p><b>Fair</b>: Produced in Portugal under fair conditions.</p><ul><li>High-quality and comfortable material</li><li>Print on the chest</li><li>Apteka.ru patch on lower back</li></ul><p><b>Please note:</b> Picture is an example only!</p>",
  },
  {
    id: 2,
    categoryId: 0,
    categorySlug: "lcd",
    title: "«Science, Bitch»",
    type: "T-Shirt",
    cover: "lcd-03.jpg",
    price: "19.99",
    date: "2021-04-28",
    params: {
      size: ["S", "M", "L", "XL"],
      color: ["Black", "White"],
      material: ["Cotton"],
    },
    desc: "<p>Own a part of esports history. Backpacks made from upcycled ESL event brandings - Each backpack is a unique piece.</p><p><b>Limited & unique</b>: By using already printed and finite materials every backpack looks slightly different and every edition is limited.</p><p><b>Sustainable</b>: We collected and re-used event brandings which normally end up in the trash.</p><p><b>Handcrafted</b>: Each event branding was spotted, washed, cutted, designed and sewn by hand.</p><p><b>Fair</b>: Produced in Portugal under fair conditions.</p><ul><li>High-quality and comfortable material</li><li>Print on the chest</li><li>Apteka.ru patch on lower back</li></ul><p><b>Please note:</b> Picture is an example only!</p>",
  },
  {
    id: 3,
    categoryId: 0,
    categorySlug: "lcd",
    title: "«OMG»",
    type: "T-Shirt",
    cover: "lcd-04.jpg",
    price: "14.99",
    date: "2021-04-28",
    params: {
      size: ["XS", "S", "M", "L", "XL"],
      color: ["Black", "White"],
      material: ["Cotton", "Polyester"],
    },
    desc: "<p>Own a part of esports history. Backpacks made from upcycled ESL event brandings - Each backpack is a unique piece.</p><p><b>Limited & unique</b>: By using already printed and finite materials every backpack looks slightly different and every edition is limited.</p><p><b>Sustainable</b>: We collected and re-used event brandings which normally end up in the trash.</p><p><b>Handcrafted</b>: Each event branding was spotted, washed, cutted, designed and sewn by hand.</p><p><b>Fair</b>: Produced in Portugal under fair conditions.</p><ul><li>High-quality and comfortable material</li><li>Print on the chest</li><li>Apteka.ru patch on lower back</li></ul><p><b>Please note:</b> Picture is an example only!</p>",
  },
  {
    id: 4,
    categoryId: 0,
    categorySlug: "lcd",
    title: "«Let`s Code, Bitch»",
    type: "T-Shirt",
    cover: "lcd-05.jpg",
    price: "19.99",
    date: "2021-04-29",
    params: {
      size: ["M", "L", "XL", "2XL"],
      color: ["Black", "White"],
      material: ["Cotton"],
    },
    desc: "<p>Own a part of esports history. Backpacks made from upcycled ESL event brandings - Each backpack is a unique piece.</p><p><b>Limited & unique</b>: By using already printed and finite materials every backpack looks slightly different and every edition is limited.</p><p><b>Sustainable</b>: We collected and re-used event brandings which normally end up in the trash.</p><p><b>Handcrafted</b>: Each event branding was spotted, washed, cutted, designed and sewn by hand.</p><p><b>Fair</b>: Produced in Portugal under fair conditions.</p><ul><li>High-quality and comfortable material</li><li>Print on the chest</li><li>Apteka.ru patch on lower back</li></ul><p><b>Please note:</b> Picture is an example only!</p>",
  },
  {
    id: 5,
    categoryId: 0,
    categorySlug: "lcd",
    title: "«Trust me»",
    type: "T-Shirt",
    cover: "lcd-06.jpg",
    price: "14.99",
    date: "2021-04-29",
    params: {
      size: ["M", "L", "XL", "2XL"],
      color: ["Black", "White"],
      material: ["Cotton", "Polyester"],
    },
    desc: "<p>Own a part of esports history. Backpacks made from upcycled ESL event brandings - Each backpack is a unique piece.</p><p><b>Limited & unique</b>: By using already printed and finite materials every backpack looks slightly different and every edition is limited.</p><p><b>Sustainable</b>: We collected and re-used event brandings which normally end up in the trash.</p><p><b>Handcrafted</b>: Each event branding was spotted, washed, cutted, designed and sewn by hand.</p><p><b>Fair</b>: Produced in Portugal under fair conditions.</p><ul><li>High-quality and comfortable material</li><li>Print on the chest</li><li>Apteka.ru patch on lower back</li></ul><p><b>Please note:</b> Picture is an example only!</p>",
  },
  {
    id: 6,
    categoryId: 0,
    categorySlug: "lcd",
    title: "«Iron Throne»",
    type: "T-Shirt",
    cover: "lcd-07.jpg",
    price: "29.99",
    date: "2021-04-30",
    params: {
      size: ["M", "L", "XL", "2XL"],
      color: ["Black"],
      material: ["Cotton"],
    },
    desc: "<p>Own a part of esports history. Backpacks made from upcycled ESL event brandings - Each backpack is a unique piece.</p><p><b>Limited & unique</b>: By using already printed and finite materials every backpack looks slightly different and every edition is limited.</p><p><b>Sustainable</b>: We collected and re-used event brandings which normally end up in the trash.</p><p><b>Handcrafted</b>: Each event branding was spotted, washed, cutted, designed and sewn by hand.</p><p><b>Fair</b>: Produced in Portugal under fair conditions.</p><ul><li>High-quality and comfortable material</li><li>Print on the chest</li><li>Apteka.ru patch on lower back</li></ul><p><b>Please note:</b> Picture is an example only!</p>",
  },
];
